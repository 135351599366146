/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const ShortBio = () => {
  const data = useStaticQuery(graphql`
    query ShortBioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(height: 100, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
      contentfulWebSiteContent(contentId: {eq: "short-bio"}) {
        contentElement {
          raw
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social
  //const test = JSON.parse()
  
  const avatar = data?.avatar?.childImageSharp?.fixed
    console.log(avatar)

  // imgStyle={{
  //   borderRadius: `50%`,
  // }}
  return (
    <div className="bio">
        
      <div>
        <p>
            {renderRichText(data.contentfulWebSiteContent.contentElement)}  <Link to="/bio">more about me...</Link>
        </p>
      </div>
    </div>
  )
}

export default ShortBio
