/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
//import "./layout.css"

//@ts-ignore
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" >

      <div className="global-header">
       <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      </div>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <div style={{paddingBottom: `2.5rem`}}>
        <main >{children}</main>
        </div>
        <footer
          style={{
            position: `absolute`,
            bottom: `0`,
            width: `100%`,
            height: `2.5rem`    
          }}
        >
          © {new Date().getFullYear()}, Vadim Polyakov
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
